
import CustomModal from "@/components/Modal/CustomModal.vue"
import { Options, Vue } from "vue-class-component"

@Options({
  components: {
    CustomModal
  },
  props: {
    modalName: {
      type: String,
      default: "whatIsScreenerModal"
    }
  }
})

export default class ParticipantsModal extends Vue {}
