import { Vue, Options } from "vue-class-component";

@Options({})
export default class Resize extends Vue {
  screenWidth = 0;

  get isMobile(): boolean {
    return this.screenWidth <= 768;
  }

  mounted(): void {
    this.getDimensions();

    window.addEventListener("resize", this.getDimensions);
  }
  unmounted(): void {
    window.removeEventListener("resize", this.getDimensions);
  }

  getDimensions(): void {
    this.screenWidth = document.documentElement.clientWidth;
  }
}
